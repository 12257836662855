<template>
    <div id="faq" class="faq-section">
        <h1>FAQ</h1>
        <div v-for="(item, index) in faqItems" :key="index" class="card w-75">
            <div class="faq-title" @click="toggleItemOpen(index)">
                <div>
                    <h2 class="font-bold">{{item.title}}</h2>
                </div>
                <svg :class="{'dropdown-open':faqItemOpen(index)}" width="20" role="button" aria-label="open dropdown" height="20" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L5 5L9 1" stroke="#FFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </div>
            <div v-if="faqItemOpen(index)" class="faq-content">
                <p v-html="item.content">
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => {
        return {
            openIndexes: [],
            isOpenIndex: [],
            isOpen: true
        }
    },
    methods: {
        toggleItemOpen(index) {
            if (this.openIndexes.includes(index)) {
                this.openIndexes = this.openIndexes.filter(i => i !== index);
            } else {
                this.openIndexes.push(index);
            }
        }
    },
    computed: {
        faqItemOpen() {
            return (index) => {
                return this.openIndexes.includes(index);
            }
        },
        faqItems() {
            return [
                {
                    title: 'Can I claim from another wallet ?',
                    content: 'Yes, you don’t have to use your wallet holding your CryptoPunks, Doodles, Azuki, OGCards or CryptoKitties to claim these free LarvaDucks, even if there is no risk to do so.<br/>You can claim them from any other wallet.'
                },
                {
                    title: 'Tokenomics',
                    content: 'There will be a maximum of <b>5000 LarvaDucks</b><br/><br/><b>Allow List :</b> <b>1 Free</b> LarvaDuck per <b>CryptoPunks/Doodles/Azuki/CryptoKitties</b> and <b>2</b> per <b>OGCards</b> you own, with a limit of <b>3 Free</b> LarvaDucks per wallet.<br/><br/><b>Public Sale :</b> Publicly available LarvaDucks cost <b>0.03 Ξ</b> with a maximum of 5 per transaction.'
                },
                {
                    title: 'Roadmap',
                    content: 'LarvaDucks were created as a tribute to a several great projects.<br/>There are no promises of future utility, as their main goal is to be fun and memeable.<br/>However, we might develop future utility if there is interest from the community.'
                },
                {
                    title: 'Should I buy a LarvaDuck ?',
                    content: 'If you just want one LarvaDuck, it may be cheaper to buy a CryptoKitty or an OGCard on the secondary market and then claim a free LarvaDuck. <br/> The BIG allowlist instead of a full free mint is to ensure a good distribution for this collection. <br/> You should only buy a LarvaDuck if you want to support this project (and next ones) and/or get more LarvaDucks than just the free claim.'
                },
                {
                    title: 'ERC721A',
                    content: 'We use the improved implementation of the ERC721 standard developed by the Azuki team, <a href="https://www.erc721a.org/" about="_blank">ERC721A</a>.<br/>Minting gas cost is substantially lower than what you are used to.<br/>Free claim might be more expensive, as there are several verifications in place for the allow list.'
                },
                {
                    title: 'License',
                    content: 'LarvaDucks are under CC0 License. You can use them as freely as you want.'
                },
                {
                    title: 'Are LarvaDucks affiliated with CryptoPunks, Doodles or Azuki ?',
                    content: 'LarvaDucks are not affiliated with any of these projects.<br/>We deconstructed Punks attributes to build completely new and unique combinations. In addition we introduced several new attributes inspired by Doodles and other projects we love.'
                }
            ]
        }
    }
}
</script>

<style>
.faq-section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    column-gap: 20px;
    align-items: center;
    color: #ffffff;

    padding: 30px;
    /* width: 75%; */
}

.faq-section h1 {
    margin-bottom: 1rem;
}
.faq-section .card {
    padding: 1.25rem 1.5rem;
    margin-bottom: 1rem;
}

.faq-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    color: #fff;
}

.faq-content {
    margin-top: 1rem;
    font-size: 1.2rem;
}

.dropdown-open {
    transform: rotate(180deg);
}
</style>