import Vue from 'vue'
import Vuex from 'vuex'
import web3ModalStore from "@/store/modules/web3Modal";
import larvaDucksStore from "@/store/modules/larvaDucks";
import claimLarvaDucksStore from "@/store/modules/claimLarvaDucks";
import { web3Plugin } from '@/plugins/web3Plugin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  plugins: [web3Plugin],
  modules: {
    web3Modal: web3ModalStore,
    larvaDucks: larvaDucksStore,
    claimLarvaDucks: claimLarvaDucksStore
  }
})
