import { getLarvaDucksContract } from "@/utils/contract";
import { ethers } from "ethers";

const larvaDucksStore = {
    state: {
        totalSupply: '?',
        maxSupply: 5000,
        claimNumber: 0,
        claimAddress: '0x0',
        hasClaimed: true,
        mintPrice: '0.03'
    },
    getters: {

    },
    mutations: {
        setTotalSupply(state, totalSupply) {
            state.totalSupply = totalSupply;
        },
        setMaxSupply(state, maxSupply) {
            state.maxSupply = maxSupply;
        },
        setClaimAddress(state, claimAddress) {
            state.claimAddress = claimAddress;
        },
        setClaimNumber(state, claimNumber) {
            state.claimNumber = claimNumber;
        },
        setHasClaimed(state, hasClaimed) {
            state.hasClaimed = hasClaimed;
        }
    },
    actions: {
        async getTotalSupply({ state, commit, dispatch }) {
            const totalSupply = await getLarvaDucksContract().totalSupply();
            commit('setTotalSupply', totalSupply.toNumber());
        },
        async getMaxSupply({ state, commit, dispatch }) {
            const maxSupply = await getLarvaDucksContract().maxSupply();
            commit('setMaxSupply', maxSupply.toNumber());
        },
        async getHasClaimed({ state, commit, dispatch }, address) {
            const hasClaimed = await getLarvaDucksContract().hasClaimedAllowList(address);
            commit('setHasClaimed', hasClaimed);
        },
        async getClaimNumber({ state, commit, dispatch }, address) {
            dispatch('getHasClaimed', address);
            const claimNumber = await getLarvaDucksContract().claimNumber(address);
            commit('setClaimNumber', claimNumber.toNumber());
        },
        async setClaimAddress({ state, commit, dispatch }, address) {
            // If the address has changed
            if (address !== state.claimAddress) {
                // If it's a valid address
                if (ethers.utils.isAddress(address)) {
                    dispatch('getClaimNumber', address);
                } else {
                    commit('setClaimNumber', 0);
                }
                commit('setClaimAddress', address);
            }
        }
    }
}
export default larvaDucksStore;