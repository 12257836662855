import { getClaimLarvaDucksContract } from "@/utils/contract";
import { ethers } from "ethers";

const claimLarvaDucksStore = {
    state: {
        claimPerCollection: 0,
        allowListedCollections: [],
        claimableCollections: [],
        claimableLarvaDucks: [],
        newClaimAddress: '0x0',
    },
    getters: {

    },
    mutations: {
        setClaimPerCollection(state, claimPerCollection) {
            state.claimPerCollection = claimPerCollection;
        },
        setAllowListedCollections(state, allowListedCollections) {
            state.allowListedCollections = allowListedCollections;
        },
        setClaimableCollections(state, claimableCollections) {
            state.claimableCollections = claimableCollections
        },
        setClaimableLarvaDucks(state, claimableLarvaDucks) {
            state.claimableLarvaDucks = claimableLarvaDucks
        },
        setNewClaimAddress(state, claimAddress) {
            state.newClaimAddress = claimAddress;
        }
    },
    actions: {
        async getClaimPerCollection({ state, commit, dispatch }) {
            const claimPerCollection = await getClaimLarvaDucksContract().claimPerCollection();
            commit('setClaimPerCollection', claimPerCollection.toNumber());
        },
        async getAllowListedCollections({ state, commit, dispatch }) {
            const allowListedCollections = await getClaimLarvaDucksContract().getAllowListedCollections();
            commit('setAllowListedCollections', allowListedCollections);
        },
        async getClaimableCollections({ state, commit, dispatch }, address) {
            const claimableCollections = await getClaimLarvaDucksContract().getClaimableCollections(address);
            commit('setClaimableCollections', claimableCollections);
        },
        async getClaimableLarvaDucks({ state, commit, dispatch }) {
            const claimableLarvaDucks = await getClaimLarvaDucksContract().getClaimableLarvaDucks();
            commit('setClaimableLarvaDucks', claimableLarvaDucks);
        },
        async setNewClaimAddress({ state, commit, dispatch }, address) {
            // If the address has changed
            if (address !== state.newClaimAddress) {
                // If it's a valid address
                if (ethers.utils.isAddress(address)) {
                    dispatch('getClaimableCollections', address);
                } else {
                    commit('setClaimableCollections', []);
                }
                commit('setNewClaimAddress', address);
            }
        }
    }
}
export default claimLarvaDucksStore;