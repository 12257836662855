<template>
    <div>
        <div class="mint-section">
            <div class="sneak-peek">
                <img src="@/assets/img/larvaducks/1.png" />
                <img src="@/assets/img/larvaducks/2.png" />
                <img src="@/assets/img/larvaducks/3.png" />
                <img src="@/assets/img/larvaducks/4.png" />
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="mint-content">
                        <p>
                            <strong>LarvaDucks</strong> is a collection of 5,000 ducks inspired by the OG of NFTs, CryptoPunks.
                        </p>
                        <p>
                            We noticed that the allow list wasn't inclusive enough, so we decided to make a contract extension to add more NFT collections on the allow list.<br>
                            You can <b>check below</b> if you are eligible for free claims of LarvaDucks.
                        </p>
                        <p>
                            You can still claim free LarvaDucks from the original allow list on the <router-link to="/">Mint</router-link> page, and even mint some through the public sale if you want to get more LarvaDucks and support the team.
                        </p>
                        <div class="mint-details">
                            <div>Allow listed collections</div>
                            <div>
                                <strong>{{ allowListedCollections.length }}</strong>
                            </div>
                        </div>
                        <div class="mint-details">
                            <div>Claim per collection</div>
                            <div>
                                <strong>{{ claimPerCollection }}</strong>
                            </div>
                        </div>
                        <div class="mint-details">
                            <div>Claimable LarvaDucks</div>
                            <div>
                                <strong>{{ claimableLarvaDucks.length }}</strong>
                            </div>
                        </div>
                        <div v-if="!active" class="mint-action">
                            <div class="mint-btn">
                                <button @click="web3Connect" class="btn btn-green w-100">
                                    <span>Connect Wallet</span>
                                </button>
                            </div>
                        </div>
                        <div v-if="active" class="claim-action">
                            <h3>
                                Claim LarvaDucks for address
                            </h3>
                            <div class="claim-address">
                                <input type="text" v-model="claimAddressInput" class="form-green">
                            </div>
                            <p>
                                Select eligible allow listed collections you want to mint free LarvaDucks from.
                            </p>
                            <div v-if="claimableCollections.length">
                                <h4>Eligible Collections</h4>
                                <div class="allow-listed-collections">
                                    <div v-for="(collection, index) in claimableCollections" :key="index"
                                        class="collection"
                                        :class="{ 'collection-selected': selectedCollections.includes(collection) }"
                                        @click="toggleCollectionSelection(collection)">
                                        <input type="checkbox" :name="collection" :checked="selectedCollections.includes(collection)">
                                        {{ mappingCollection[collection] }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="nonClaimableCollections.length">
                                <h4>Non Eligibile Collections</h4>
                                <div class="allow-listed-collections">
                                    <div v-for="(collection, index) in nonClaimableCollections" :key="index"
                                        class="collection"
                                        :class="{ 'collection-selected': selectedCollections.includes(collection) }">
                                        <input type="checkbox" :name="collection" :checked="selectedCollections.includes(collection)" @click="checkboxSelection">
                                        {{ mappingCollection[collection] }}
                                    </div>
                                </div>
                            </div>
                            <div class="claim-btn">
                                <button @click="claim" class="btn btn-green w-100">
                                    <span>{{ claimAllLabel }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <faq></faq>
        <v-dialog
            @opened="onModalOpened"
            @closed="onModalClosed" />
    </div>
    
</template>

<script>
import { mapActions } from "vuex";
import { getClaimLarvaDucksContract } from "@/utils/contract";
import { web3Modal, larvaDucks, claimLarvaDucks } from "../config/mixins";
import FAQ from '../components/FAQ.vue'

export default {
    components: {
        'faq': FAQ
    },
    mixins: [web3Modal, claimLarvaDucks],
    data: () => {
        return {
            selectedCollections: [],
            mappingCollection: {
                '0x00C65f7DAFD5e9aF6aC935c1F05696b677Ef3240': 'LarvaDucks',
                '0xeB3a9A839dFeEaf71db1B4eD6a8ae0cCB171b227': 'MOAR',
                '0x620b70123fB810F6C653DA7644b5dD0b6312e4D8': 'Space Doodles',
                '0x3DB5463A9e2d04334192C6f2DD4B72DeF4751A61': 'ALPACADABRAZ',
                '0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7': 'Meebits',
                '0x79FCDEF22feeD20eDDacbB2587640e45491b757f': 'mfers',
                '0x6080B6D2C02E9a0853495b87Ce6a65e353b74744': 'EightBit Me'
            },
            modalShown: false
        }
    },
    methods: {
        ...mapActions([
            'connect',
            'getClaimableCollections',
            'getClaimableLarvaDucks',
            'setNewClaimAddress'
        ]),
        async web3Connect() {
            this.connect();
        },
        async claim() {
            try {
                const tx = await getClaimLarvaDucksContract().claimForCollections(this.newClaimAddress, this.selectedCollections);
                // console.log('tx :', tx);
                this.showModal('claim', { hash: tx.hash });
                // Claim succeed
                const receipt = await tx.wait();
                // console.log('receipt :', receipt);
                // Update claimable collections
                this.getClaimableCollections(this.newClaimAddress);
                this.getClaimableLarvaDucks();
            } catch (err) {
                console.log('/!\\ ERROR /!\\');
                let msg;
                if (err.message) {
                    console.log('Error Message :', err.message);
                    msg = err.message;
                }
                this.showModal('error', { err: msg });
            }
        },
        onModalOpened() {
            this.modalShown = true;
        },
        onModalClosed() {
            this.modalShown = false;
        },
        showModal(type, options) {
            let title = '';
            let text = '';
            if (type === 'mint' && options && options.hash) {
                const link = `https://etherscan.io/tx/${options.hash}`;
                title = 'Minting';
                text = `Your minting is in progress, you'll soon be able to track the process <a href="${link}" target="_blank">here</a>.`;
            } else if (type === 'claim' && options && options.hash) {
                const link = `https://etherscan.io/tx/${options.hash}`;
                title = 'Claiming';
                text = `Your minting is in progress, you'll soon be able to track the process <a href="${link}" target="_blank">here</a>.`;
            } else if (type === 'error') {
                title = 'Error';
                text = 'There was an error during the transaction.';
                if (options && options.err) {
                    text = `${options.err}`;
                } 
            }
            if (!this.modalShown && title && text) {
                this.$modal.show('dialog', {
                    title,
                    text,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                });
            }
        },
        hideModal() {
            if (this.modalShown) {
                this.$modal.hide('dialog')
            }
        },
        toggleCollectionSelection(collection) {
            if (this.selectedCollections.includes(collection)) {
                this.selectedCollections = this.selectedCollections.filter(c => c !== collection);
            } else {
                this.selectedCollections.push(collection);
            }
        },
        checkboxSelection(e) {
            e.preventDefault();
        }
    },
    computed: {
        nonClaimableCollections() {
            return this.allowListedCollections.filter(collection => !this.claimableCollections.includes(collection));
        },
        claimNumber() {
            return this.claimPerCollection * this.selectedCollections.length;
        },
        claimAllLabel() {
            return `Claim all (${this.claimNumber})`;
        },
        claimAddressInput : {
            get() {
                return this.newClaimAddress;
            },
            set(newValue) {
                this.setNewClaimAddress(newValue);
            }
        }
    },
    watch: {
        claimableCollections(newValue, oldValue) {
            if (newValue) {
                this.selectedCollections = newValue;
            }
        }
    }
}
</script>

<style>
.claim-action h3 {
    text-align: center;
}
.claim-action p {
    margin-top: 1rem;
}

.allow-listed-collections {
    margin: 20px;
    /* height: 50px; */
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
}

.collection {
    user-select: none;
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 1rem;
}

.collection-selected {
    color: #658796;
    font-weight: bold;
    background-color: #ffffff;
}
</style>