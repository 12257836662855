<template>
    <nav id="nav">
        <div id="nav-title">
            <router-link to="/">LarvaDucks</router-link>
        </div>
        <div id="nav-links">
            <router-link to="/">Mint</router-link>
            <router-link to="/claim">Claim</router-link>
            <router-link to="/wallet">Wallet</router-link>
        </div>
        <button v-if="!web3Modal.active" @click="connect" class="btn btn-navbar btn-green">
            <span>Connect</span>
        </button>
        <button v-else @click="connect" class="btn btn-navbar btn-green btn-address">
            <span>{{ shortenedAddress }}</span>
        </button>
        
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { web3Modal } from "@/config/mixins";

export default {
    data: () => {
        return {
        }
    },
    mixins: [web3Modal],
    methods: {
        connect() {
            this.$emit('connect');
        } 
    },
    computed: {
        ...mapGetters(['shortenedAddress'])
    }
}
</script>

<style>
#nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;

    /* height: 70px; */
    margin: 0;
    padding: 20px;
    color: #ffffff;
    background-color: #658796;
    border-bottom: 2px solid #000000;
    font-size: 1.3rem;
    border-bottom: 1px solid #fff;
}

#nav-title {
    font-weight: 800;
    font-size: 2rem;
    color: white!important;
}

#nav-title a {
    font-weight: 800;
    font-size: 2rem;
    color: inherit!important;
}

#nav-title a:hover {
    color: inherit;
}

#nav-links a {
    /* font-weight: bold; */
    /* font-size: 1.3rem; */
    padding: 5px 10px;
}

.router-link-exact-active {
    color: black;
}

</style>