<template>
    <div>
        <div class="mint-section">
            <div class="sneak-peek">
                <img src="@/assets/img/larvaducks/1.png" />
                <img src="@/assets/img/larvaducks/2.png" />
                <img src="@/assets/img/larvaducks/3.png" />
                <img src="@/assets/img/larvaducks/4.png" />
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="mint-content">
                        <p>
                            <strong>LarvaDucks</strong> is a collection of 5,000 ducks inspired by the OG of NFTs, CryptoPunks.
                        </p>
                        <p>
                            Every holder of <strong>CryptoPunks</strong>, <strong>Doodles</strong>, <strong>Azuki</strong>, <strong>OGCards</strong> or <strong>CryptoKitties</strong> can claim <strong>1 FREE</strong> LarvaDuck per collection they hold, up to
                            <strong>3 FREE</strong> LarvaDucks per wallet.
                        </p>
                        <div class="mint-details">
                            <div>NFT Minted</div>
                            <div>
                                <strong>{{ totalSupply }}</strong> / <strong>{{ maxSupply }}</strong>
                            </div>
                        </div>
                        <div class="mint-details">
                            <div>Price</div>
                            <div>
                                <strong>FREE</strong> /
                                <strong>{{ mintPrice }}</strong>
                                Ξ
                            </div>
                        </div>
                        <div v-if="!active" class="mint-action">
                            <div class="mint-btn">
                                <button @click="web3Connect" class="btn btn-green w-100">
                                    <span>Connect Wallet</span>
                                </button>
                            </div>
                        </div>
                        <div v-if="active" class="mint-action">
                            <p>
                                Amount to mint (5 max per tx)
                            </p>
                            <div class="mint-counter">
                                <input type="number" v-model="mintAmount" class="form-green">
                            </div>
                            <div class="mint-btn">
                                <button @click="mint" class="btn btn-green w-100">
                                    <span>Mint</span>
                                </button>
                            </div>
                        </div>
                        <div v-if="active" class="claim-action">
                            <p>
                                Claim all LarvaDucks for address :
                            </p>
                            <div class="claim-address">
                                <input type="text" v-model="claimAddressInput" class="form-green">
                            </div>
                            <div class="claim-btn">
                                <button @click="claim" class="btn btn-green w-100">
                                    <span>{{ claimAllLabel }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <faq></faq>
        <v-dialog
            @opened="onModalOpened"
            @closed="onModalClosed" />
    </div>
    
</template>

<script>
import { mapActions } from "vuex";
import { getLarvaDucksContract } from "@/utils/contract";
import { web3Modal, larvaDucks } from "../config/mixins";
import { ethers } from 'ethers'
import FAQ from '../components/FAQ.vue'

export default {
    components: {
        'faq': FAQ
    },
    mixins: [web3Modal, larvaDucks],
    data: () => {
        return {
            mintAmount: 5,
            modalShown: false
        }
    },
    methods: {
        ...mapActions([
            'connect',
            'setClaimAddress',
            'getHasClaimed',
            'getTotalSupply'
        ]),
        async web3Connect() {
            this.connect();
        },
        async mint() {
            const totalPrice = this.mintAmount * parseFloat(this.mintPrice);
            // console.log('mintPrice :', this.mintPrice);
            // console.log('mintAmount :', this.mintAmount);
            // console.log('totalPrice :', totalPrice);
            // console.log('totalPrice toString :', totalPrice.toString());
            try {
                const tx = await getLarvaDucksContract().publicMint(this.account, this.mintAmount, { value: ethers.utils.parseEther(totalPrice.toString())});
                // console.log('tx :', tx);
                this.showModal('mint', { hash: tx.hash });
                // Mint Succeed
                const receipt = await tx.wait();
                // console.log('receipt :', receipt);
                // Retrieve totalSupply
                this.getTotalSupply();
            } catch (err) {
                console.log('/!\\ ERROR /!\\');
                let msg;
                if (err.message) {
                    console.log('Error Message :', err.message);
                    msg = err.message;
                }
                this.showModal('error', { err: msg });
            }
        },
        async claim() {
            try {
                const tx = await getLarvaDucksContract().claimMint(this.claimAddress, this.claimNumber);
                // console.log('tx :', tx);
                this.showModal('claim', { hash: tx.hash });
                // Claim succeed
                const receipt = await tx.wait();
                // console.log('receipt :', receipt);
                // Retrieve totalSupply
                this.getTotalSupply();
                this.getHasClaimed(this.claimAddress);
            } catch (err) {
                console.log('/!\\ ERROR /!\\');
                let msg;
                if (err.message) {
                    console.log('Error Message :', err.message);
                    msg = err.message;
                }
                this.showModal('error', { err: msg });
            }
        },
        onModalOpened() {
            this.modalShown = true;
        },
        onModalClosed() {
            this.modalShown = false;
        },
        showModal(type, options) {
            let title = '';
            let text = '';
            if (type === 'mint' && options && options.hash) {
                const link = `https://etherscan.io/tx/${options.hash}`;
                title = 'Minting';
                text = `Your minting is in progress, you'll soon be able to track the process <a href="${link}" target="_blank">here</a>.`;
            } else if (type === 'claim' && options && options.hash) {
                const link = `https://etherscan.io/tx/${options.hash}`;
                title = 'Claiming';
                text = `Your minting is in progress, you'll soon be able to track the process <a href="${link}" target="_blank">here</a>.`;
            } else if (type === 'error') {
                title = 'Error';
                text = 'There was an error during the transaction.';
                if (options && options.err) {
                    text = `${options.err}`;
                } 
            }
            if (!this.modalShown && title && text) {
                this.$modal.show('dialog', {
                    title,
                    text,
                    buttons: [
                        {
                            title: 'OK',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        }
                    ]
                });
            }
        },
        hideModal() {
            if (this.modalShown) {
                this.$modal.hide('dialog')
            }
        }
    },
    computed: {
        claimAllLabel() {
            return `Claim all (${this.claimNumber})`;
        },
        claimAddressInput : {
            get() {
                return this.claimAddress;
            },
            set(newValue) {
                this.setClaimAddress(newValue);
            }
        }
    },
    watch: {

    }
}
</script>

<style>

.mint-section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: 10px;
    align-items: flex-start;
    color: #ffffff;

    padding: 30px;
}

.sneak-peek {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 10px;
    max-width: 400px;
}

.sneak-peek img {
    width: 45%;
    height: 45%;
}

@media (max-width: 1000px) {
    .mint-section {
        flex-flow: row wrap;
    }

    .sneak-peek {
        flex-flow: row nowrap;
        max-width: 100%;
    }

    .sneak-peek img {
        width: 20%;
        height: 20%;
    }
}

.mint-section img {
    border: 2px solid #000000;
    /* border: 1px solid rgba(0,0,0,.125); */
    border-radius: 0.5rem;

    border: 1px solid #fff;
    border-radius: 1rem;
}

.mint-details {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;

    display: flex;
    justify-content: space-between;
}

.mint-content {
    font-size: 1.2rem;
}

.mint-content p {
    margin-bottom: 1rem;
}

.claim-action,
.mint-action {
    padding: 1rem 0;
}

.claim-action div,
.mint-action div {
    margin-top: 0.5rem;
}
.claim-action p,
.mint-action p {
    text-align: center;
    /* font-size: .9rem; */
    margin-bottom: 0;
}

.claim-address,
.mint-counter {
    width: 100%;
    height: 54px;
    background: transparent;
    position: relative;
    text-align: center;
}

.claim-address input,
.mint-counter input {
    /* background-color: transparent; */
    border: 1px solid #fff;
    border-radius: 0.5rem;
    height: 54px;
    width: 50%;
    font-size: 1.7rem;
    color: #fff;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
    font-weight: 700;
}

.claim-address input[type=text] {
    width: 75%;
    font-size: 1rem;
}

</style>