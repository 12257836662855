<template>
  <div id="app">
    <navbar @connect="web3Connect"></navbar>
    <router-view/>
    <larvaFooter></larvaFooter>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { mapActions } from "vuex";
import { web3Modal } from "./config/mixins";

// Create Modal
const providerOptions = {
  /* See Provider Options Section */
  injected: {
    display: {
      name: "Metamask",
      description: "Connect with the provider in your Browser"
    },
    package: null
  },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "5789bf5ac7da4c52939f1ab076a35313" // required
    }
  }
};
const modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: false, // optional
  providerOptions // required
});

export default {
  components: {
    Navbar,
    'larvaFooter': Footer
  },
  mixins: [web3Modal],
  data() {
    return {
      theme: 'light'
    }
  },
  created() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.theme = 'dark'
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.$store.commit('setWeb3Modal', modal)
      if (modal.cachedProvider) {
        this.connect();
        // this.$store.dispatch('connect');
      }
    })
  },
  methods: {
    ...mapActions([
      'connect',
      'resetApp'
    ]),
    async web3Connect() {
      if (!this.active) {
        this.connect();
        // this.$store.dispatch('connect');
      } else {
        this.resetApp();
        // this.$store.dispatch('resetApp');
      }
    }
  }
}
</script>

<style>
@import './assets/styles/index.css';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
