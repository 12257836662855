import {mapGetters, mapState} from "vuex";

const web3Modal = {
    computed: {
        ...mapState(['web3Modal']),
        active() {
            return this.web3Modal.active;
        },
        account() {
            return this.web3Modal.account;
        }
    }
};

const larvaDucks = {
    computed: {
        ...mapState(['larvaDucks']),
        totalSupply() {
            return this.larvaDucks.totalSupply;
        },
        maxSupply() {
            return this.larvaDucks.maxSupply;
        },
        claimAddress() {
            return this.larvaDucks.claimAddress;
        },
        claimNumber() {
            return this.larvaDucks.hasClaimed ? 0 : this.larvaDucks.claimNumber;
        },
        mintPrice() {
            return this.larvaDucks.mintPrice;
        }
    }
};

const claimLarvaDucks = {
    computed: {
        ...mapState(['claimLarvaDucks']),
        claimPerCollection() {
            return this.claimLarvaDucks.claimPerCollection;
        },
        allowListedCollections() {
            return this.claimLarvaDucks.allowListedCollections;
        },
        claimableCollections() {
            return this.claimLarvaDucks.claimableCollections;
        },
        claimableLarvaDucks() {
            return this.claimLarvaDucks.claimableLarvaDucks;
        },
        newClaimAddress() {
            return this.claimLarvaDucks.newClaimAddress;
        }
    }
};

const urls = {
    computed: {
        discordUrl() {
            return process.env.VUE_APP_DISCORD_URL;
        },
        openSeaUrl() {
            return process.env.VUE_APP_OPENSEA_URL;
        },
        etherscanUrl() {
            return `https://etherscan.io/address/${process.env.VUE_APP_LARVADUCKS_ADDRESS}`;
        },
        twitterUrl() {
            return process.env.VUE_APP_TWITTER_URL;
        }
    }
}

export {
    web3Modal,
    larvaDucks,
    claimLarvaDucks,
    urls
};