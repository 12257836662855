const web3Plugin = store => {
    store.subscribe((mutation, state) => {
        // The mutation comes in the format of `{ type, payload }`.
        // console.log('MUTATION :', mutation.type);
        if (mutation.type === 'setAccount') {
            const account = store.getters.account;
            if (account) {
                // Set claimAddress
                store.commit('setClaimAddress', account);
                // Load totalSupply, maxSupply and claimNumber
                store.dispatch('getTotalSupply');
                store.dispatch('getMaxSupply');
                store.dispatch('getClaimNumber', account);
                // NEW CLAIM
                store.commit('setNewClaimAddress', account);
                store.dispatch('getClaimPerCollection');
                store.dispatch('getAllowListedCollections');
                store.dispatch('getClaimableCollections', account);
                store.dispatch('getClaimableLarvaDucks');
            }
        }
    })
};

export {
    web3Plugin
};