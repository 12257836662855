import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import VueAnalytics from 'vue-analytics';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-310306663',
  router
});

Vue.use(VModal, { dialog: true });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
