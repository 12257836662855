import Vue from 'vue'
import VueRouter from 'vue-router'
import Mint from '../views/Mint.vue'
import Claim from '../views/Claim.vue'
import Wallet from '../views/Wallet.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet
  },
  {
    path: '/claim',
    name: 'Claim',
    component: Claim
  },
  { path: '*', component: Mint }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
