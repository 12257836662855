import larvaDucksAbi from '@/config/abi/LarvaDucks.json';
import claimLarvaDucksAbi from '@/config/abi/ClaimLarvaDucks.json';
import { ethers } from "ethers";
import { simpleRpcProvider } from "@/utils/web3";
import store from '@/store';

export const getLarvaDucksContract = () => {
    const { library } = store.state.web3Modal;
    const signer = library.getSigner();
    return getContract(larvaDucksAbi, process.env.VUE_APP_LARVADUCKS_ADDRESS, signer);
}

export const getClaimLarvaDucksContract = () => {
    const { library } = store.state.web3Modal;
    const signer = library.getSigner();
    return getContract(claimLarvaDucksAbi, process.env.VUE_APP_CLAIMLARVADUCKS_ADDRESS, signer);
}

const getContract = (abi, address, signer = null) => {
    const signerOrProvider = signer ?? simpleRpcProvider;
    return new ethers.Contract(address, abi, signerOrProvider);
}